// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("/opt/build/repo/src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-en-js": () => import("/opt/build/repo/src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-sv-js": () => import("/opt/build/repo/src/pages/about/index.sv.js" /* webpackChunkName: "component---src-pages-about-index-sv-js" */),
  "component---src-pages-contact-index-en-js": () => import("/opt/build/repo/src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-sv-js": () => import("/opt/build/repo/src/pages/contact/index.sv.js" /* webpackChunkName: "component---src-pages-contact-index-sv-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-sv-js": () => import("/opt/build/repo/src/pages/index.sv.js" /* webpackChunkName: "component---src-pages-index-sv-js" */),
  "component---src-pages-services-index-en-js": () => import("/opt/build/repo/src/pages/services/index.en.js" /* webpackChunkName: "component---src-pages-services-index-en-js" */),
  "component---src-pages-services-index-sv-js": () => import("/opt/build/repo/src/pages/services/index.sv.js" /* webpackChunkName: "component---src-pages-services-index-sv-js" */),
  "component---src-pages-thanks-en-js": () => import("/opt/build/repo/src/pages/thanks.en.js" /* webpackChunkName: "component---src-pages-thanks-en-js" */),
  "component---src-pages-thanks-sv-js": () => import("/opt/build/repo/src/pages/thanks.sv.js" /* webpackChunkName: "component---src-pages-thanks-sv-js" */)
}

